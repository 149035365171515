@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
.percentage {
  width: 40px;
  height: 40px;
}

/* .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.signup-inner--form-model {
    padding: 0px;
    background-color: #fff;
    position: relative;
    margin: 0px;
}

.signup-inner--form-model .single-field {
    width: 100%;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #999;
    padding: 5px 10px;
    margin-bottom: 0px;
} */
body{
    /* background: #E5E5E5; */
    /* font-family: 'Roboto'; */
}
.type-social{
    width:  100%;
    text-align: center !important;
}
.badge-danger{
    background-color: #dc3545 !important;
}

.author {
    font-weight: 800;
    line-height: 1;
    text-transform: capitalize;
    font-size: 18px;
    color: #201E1E;
}
.fadeBlock{
    display: block !important;
    opacity: 1 !important;
}
.fadeUnloack{
    display: none !important;
    opacity: 0;
}

.profile-dropdown1{
    display: none !important;
}
.profile-body1{
    display: block !important;
 }
.Loader{
    width: 378px;
    height: 237px;
    position: fixed;
    left: 38%;
    top: 200px;
}

/* .new-bg{
    background-image: url("../Image/login-bg.jpg");
    height: 100%;
    background-size: cover;
    background-position: center;
  } */
  .new-bg .container-fluid,.new-bg .row.no-gutters{min-height: 100vh;position: relative;}
  .signup-inner--form {
    padding: 12px 50px 50px !important;
    background-color: none !important;
    /* position: relative;
    height: calc(100vh - 80px);
    padding-bottom: 240px !important;
    overflow: hidden;
    overflow-y: auto; */
}
.signup-form-wrapper {
    /* padding-top: 0;
    padding-right: 0;
    flex: 0 0 780px;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-70%, -55%); */
    height: 100vh;
    padding: 0;
    width: 100%;
}

.box-content-custom{
  margin: 10px 0px;
  overflow: hidden;
}
.box-content-custom .post-share-btn{    
    min-width: 170px;
}
.box-custom{
  display: block;
    padding: 5px;
    margin: 10px 0px;
}

.h1New{
  
    padding: 13px 0px;

}

.h2New{
    padding: 13px 0px;
}
.h3New{
    padding: 13px 0px;
}

.lets-Go{
    background: rgba(68, 99, 238, 1);
    color: white;
    text-align: center;
    font-size: 14px;
    margin: auto;
    max-width: 200px;
    height: 35px;
    display: flex;
}
.lets-inter{
    margin: auto;
}

.lets-Go .lets-inter a{
    color: white;
    font-weight: 700;
}


.lets-OR{
    background: none;
    color: white;
    text-align: center;
    font-size: 26px;
    margin: auto;
}

.lets-OR .lets-inter-or a{
    color: rgba(150, 150, 150, 1);
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    margin-top: 20px;
    text-decoration: underline;

}
.lets-OR .lets-inter-se a{
    color: black;
    font-weight: 300;
    margin: auto;

}

.lets-inter-or{
    color: Red;
    text-align: center;
    font-size: 26px;
   
}


.createA{
    padding: 20px 40px;
    background: rgba(68, 99, 238, 1);
    color: white;
    border: 1px solid rgba(68, 99, 238, 1);
    display: inline-block;
}
.createA:hover{
    background: none;
    color: #42b4fb;
}

.form {
    margin: 0 auto;
    padding: 100px 30px 0;
  
    @media (min-width: 800px) {
      max-width: 60%;
    }
  }
  
  .input-range {
    margin-bottom: 160px;
  }
  


  body { padding: 0;margin: 0; }


/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 12px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
}
.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}
.w-38{width: 37% !important; }

ul.Genres_listing {
    display: flex;
    flex-flow: wrap;
    max-height: 315px;
    overflow: auto;
}
ul.Genres_listing li {
    flex: 0 0 50%;
    padding: 0 5px;
    margin-bottom: 10px;
}
ul.Genres_listing a {
    display: block;
    padding: 10px;
    background: #4463EE;
    color: #fff !important;
    border: 1px solid #3394d1;
    border-radius: 5px;
    cursor: pointer;
}
ul.Genres_listing a:hover{
    background: #2f9ade;
}
.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    background: #fff;
    z-index: 99;
}
.css-1okebmr-indicatorSeparator{
    display: none !important;
}
.county_slect .css-yk16xz-control{
    width: 100%;
    border: none;
    border-bottom: none;
    border-radius: 0;
    font-size: 13px;
    height: 34px;
    line-height: 30px;
    margin-bottom: 27px;
    background-color: #f0f0f0;
}
.css-1pahdxg-control{
    border: none !important;
    outline: none !important;
    
}

.login-info {
    background: #4463EE;
    height: 100vh;
    text-align: center;
    padding: 120px;
}
.steps .item p {
    font-size: 22px;
    line-height: 25px;
    color: #fff;
}
.steps .item {
    margin-top: 50px;
    position: relative;
}
.steps .item:before {
    content: '';
    position: absolute;
    width: 125px;
    height: 1px;
    background: #fff;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -30px;
}
.steps .item:last-child::before {
    display: none;
}
.action.s-wrapper {
    max-width: 430px;
    margin: 0 auto;
    /* position: absolute;
    bottom: 40px;
    left: 0;
    right: 0; */
}
.social-buttons {
    display: flex;
}
.seprater {
    margin: 30px 0;
    height: 1px;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.1);
}
.seprater span {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 30px;
    height: 20px;
    top: -10px;
    background: #fff;
}
.scroll-section {
    height: calc(100vh - 310px);
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 20px;
}
.login .scroll-section {
    height: calc(100vh - 410px);
}
.form-title {
    text-align: left;
    padding: 20px 20px 20px 60px;
}
.form-title .create-acc {
    padding: 0;
    font-size: 40px;
}
.form-title .sub-heading {
    font-size: 24px;
    color: rgba(204, 204, 204, 1);
}
h6.author span {
    font-size: 12px;
    color: rgba(212, 212, 212, 1);
    margin-left: 10px;
}
.navbar-light .navbar-nav .nav-link {
    font-size: 18px;
    color: rgba(111, 111, 111, 1);
    padding: 1rem 40px;
}
.navbar-light .navbar-nav .active> .nav-link, .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(255 255 255);
    background: #4463eeab;
}
.share-content-box p{
    font-size: 12px;
    margin-top: 6px;
}
.submit-btn.light {
    background: transparent;
    border: 1px solid #4463EE;
    color: #4463EE;
}
.submit-btn.light:hover {
    background: #4463EE;
    border: 1px solid transparent;
    color: #fff;
}
@media(max-width:1680px){
    .signup-inner--form.signup-inner--form1 .single-field{margin-bottom: 15px;}
    .signup-inner--form.signup-inner--form1 .nice-select{margin-bottom: 15px;}
}
@media(max-width:1440px){
    .signup-inner .title {padding: 12px 0;}
    .signup-form-wrapper .create-acc {padding-bottom: 0;}
    .signup-inner--form {padding: 12px 100px 30px !important;}
    .signup-inner--form.signup-inner--form1 .create-acc{padding-bottom: 0;}
    .signup-inner--form.signup-inner--form1 .single-field{margin-bottom: 10px;}
    .signup-inner--form.signup-inner--form1 .nice-select{margin-bottom: 10px;}
    .createA {padding: 8px 40px;display: inline-block;margin-top: 10px;}
    .signup-inner--form.signup-inner--form1 {padding: 10px 40px 0px !important;margin-bottom: 0;}
    .signup-inner .terms-condition {padding-top: 15px;}
}
@media(max-width:1366px){
    .signup-form-wrapper .create-acc {font-size: 26px;}
    .signup-inner--form {padding: 12px 60px 30px !important;}
    .submit-btn {padding: 10px 20px;margin-top: 10px;}
    .signup-inner .title {padding: 12px 0;}
}
@media (max-width: 1199px) {
    .navbar-light .navbar-nav .nav-link {
      font-size: 16px;
      padding: 1rem 20px;
  }
  }
@media(max-width:768px){
    .mobile-profile-ul { padding-top: 50px; }
.mobileNotif{display: none;}
/* .new-bg{background-image: url("../Image/bg-on-responsive.jpg");} */
/* .signup-form-wrapper {position: static;-webkit-transform: translate(0, 0);transform: translate(0, 0);padding-bottom: 0;margin-bottom: 0;width: calc(100% - 30px);flex: 0 0 calc(100% - 30px);background: rgba(255, 255, 255, 0.90);border-radius: 5px;padding: 35px 15px 20px 15px;} */
.signup-inner--form {padding: 12px 0 10px !important;margin-bottom: 0%0;}
.signup-inner--form.signup-inner--form1 {padding: 10px 0 0px !important;}
}  

@media(max-width:575px){
    .mobile-profile-ul { padding-top: 50px; }
    .signup-inner .title {padding: 14px 0;}
}
@media(max-width:414px){
     .mobile-profile-ul { padding-top: 50px; }
    .submit-btn{margin-top: 10px;}
    .signup-inner--form .col-6{flex: 0 0 100%;max-width: 100%;}
    .loginBtn.loginBtn--google{min-width: 175px;}
}

.postImage{
    margin-top : 10px !important;
    margin-bottom: 0px !important;
}


/* body {
  font-family: 'Open Sans', sans-serif;
} */

.head {
  font-size: 25px;
  font-weight: 200;
}

.blue-btn:hover,
.blue-btn:active,
.blue-btn:focus,
.blue-btn {
  background: transparent;
  border: solid 1px #27a9e0;
  border-radius: 3px;
  color: #27a9e0;
  font-size: 16px;
  margin-bottom: 20px;
  outline: none !important;
  padding: 10px 20px;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  height: 43px;
  margin-top: 0;
}

.fileUpload input.uploadlogo {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 42px;
}

/*Chrome fix*/
input::-webkit-file-upload-button {
  cursor: pointer !important;
  height: 42px;
  width: 100%;
}
.iMnhxj {
  border: 0px solid #ddd;
  border-radius: 2px;
  height: auto;
  margin: 0;
  padding: 10px;
  margin: 0 auto;
  display: block;
}

.iMnhxj ul {
  text-align: center;
  margin: 10px;
  padding: 10px;
  font-size: 20px;
}

.fav {
  font-size: 40px;
}
.active1 {
  color: #4463ee;
}

.title-iput {
  width: 100%;
  border: 1px solid #dddddd;
}
.left {
  float: left;
}
.plus-btn {
  right: 3px;
  font-size: 10px;
  color: #4463EE;
  line-height: 1;
  padding: 4px;
  border-radius: 40px;
  background-color: transparent;
  text-transform: uppercase;
  border: 1px solid #4463EE;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.author {
  font-weight: 600 !important;
}
.l-2 {
  line-height: 2;
}
.tvclub-icon {
  text-align: center;
  margin-bottom: 25px;
}
.share-box-inner > button {
  text-align: right !important;
}

.row {
  margin: 0;
}
.movie-bg {
  height: 200px;
}
.badge {
  background: #4463EE;
  color: #fff !important;
}
.header-top-navigation nav ul li {
  display: inline-block;
}
.main-menu-link > i {
  background: #4463EE;
  color: #fff !important;
  padding: 10px;
  border-radius: 50%;
}
.main-menu-link > i:hover {
  background: #000;
  color: #fff !important;
  padding: 10px;
  border-radius: 50%;
}
.cast-card {
  width: 18%;
  border: none;
  margin: 5px 9px;
  border-radius: 0;
  padding: 10px 10px;
  margin-bottom: 4px;
  box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  background-color: #fff;
  text-align: center;
}
.card-content {
  text-align: left;
  padding: 20px 0;
}
.profile-menu-wrapper {
  padding: 10px 0;
}
.cast-heading {
  text-transform: capitalize;
}

.activedash {
  background-color: #41b4fc;
  border: 1px solid black;
  border-radius: 3px;
  color: white;
}

.friend-card {
  padding: 10px;
  width: 33%;
  margin-bottom: 20px;
  position: relative;
}
.friend-card .card {
  padding: 10px;
  border-radius: 4px;
  height: 100%;
}
.friend-card .profile-image {
  max-width: 100%;
  height: 100px;
  width: 100px;
  margin: 0 auto;
}
.friend-list {
  color: #4463ee;
  font-weight: 700;
  font-size: 30px;
}
.friend-back {
  color: #000;
}
.back-btn {
  background: #4463ee;
  color: #fff;
  padding: 5px 15px;
  border-radius: 50px;
}
.profile-image {
  width: 230px;
  height: 180px;
}
.profile-image img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
}
.friend-card .card .episode_count a {
  font-size: 14px;
  margin-top: 10px;
  display: block;
  color: #333333;
  font-weight: 600;
}
.friends.modal-title {
  margin-bottom: 30px;
  text-align: center;
}
.friends-card-list {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

.actions-btn {
  position: absolute;
  left: 10px;
  z-index: 9;
  top: 15px;
  width: calc(100% - 20px);
  justify-content: space-between;
  display: flex;
  top: 10px;
}

.actions-btn span {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #1b8f0a;
  cursor: pointer;
}
/* .actions-btn span + span{
  background: red;
} */

.actions-btn .fa-close{
  background: red;
}


.friend-list-page .friend-card {
    width: 25%;
}

.friend-list-page .friend-card .profile-image {
    width: 170px;
    height: 170px;
}

.friend-list-page .friend-card .episode_count  a {
    font-size: 18px;
    margin-top: 20px;
}



.friend-card {
  padding: 10px;
  width: 33%;
  margin-bottom: 20px;
  position: relative;
}
.friend-card .card {
  padding: 10px;
  border-radius: 4px;
  height: 100%;
}
.friend-card .profile-image {
  max-width: 100%;
  height: 100px;
  width: 100px;
  margin: 0 auto;
}
.friend-list {
  color: #4463ee;
  font-weight: 700;
  font-size: 30px;
}
.friend-back {
  color: #000;
}
.back-btn {
  background: #4463ee;
  color: #fff;
  padding: 5px 15px;
  border-radius: 50px;
}
.profile-image {
  width: 230px;
  height: 180px;
}
.profile-image img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
}
.friend-card .card .episode_count a {
  font-size: 14px;
  margin-top: 10px;
  display: block;
  color: #333333;
  font-weight: 600;
}
.friends.modal-title {
  margin-bottom: 30px;
  text-align: center;
}
.friends-card-list {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

.actions-btn {
  position: absolute;
  left: 10px;
  z-index: 9;
  top: 15px;
  width: calc(100% - 20px);
  justify-content: space-between;
  display: flex;
  top: 10px;
}

.actions-btn span {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #1b8f0a;
  cursor: pointer;
}
/* .actions-btn span + span{
  background: red;
} */

.actions-btn .fa-close{
  background: red;
}


.friend-list-page .friend-card {
    width: 25%;
}

.friend-list-page .friend-card .profile-image {
    width: 170px;
    height: 170px;
}

.friend-list-page .friend-card .episode_count  a {
    font-size: 18px;
    margin-top: 20px;
}
.iMnhxj {
  border: 0px solid #ddd;
  border-radius: 2px;
  height: auto;
  margin: 0;
  padding: 10px;
  margin: 0 auto;
  display: block;
}

.iMnhxj ul {
  text-align: center;
  margin: 10px;
  padding: 10px;
  font-size: 20px;
}

.fav {
  font-size: 40px;
}
.active1 {
  color: #4463ee;
}

.title-iput {
  width: 100%;
  border: 1px solid #dddddd;
}
.left {
  float: left;
}
.plus-btn {
  right: 3px;
  font-size: 10px;
  color: #4463EE;
  line-height: 1;
  padding: 4px;
  border-radius: 40px;
  background-color: transparent;
  text-transform: uppercase;
  border: 1px solid #4463EE;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.author {
  font-weight: 600 !important;
}
.l-2 {
  line-height: 2;
}
.tvclub-icon {
  text-align: center;
  margin-bottom: 25px;
}
.share-box-inner > button {
  text-align: right !important;
}

.row {
  margin: 0;
}
.movie-bg {
  height: 200px;
}
.badge {
  background: #4463EE;
  color: #fff !important;
}
.header-top-navigation nav ul li {
  display: inline-block;
}
.main-menu-link > i {
  background: #4463EE;
  color: #fff !important;
  padding: 10px;
  border-radius: 50%;
}
.main-menu-link > i:hover {
  background: #000;
  color: #fff !important;
  padding: 10px;
  border-radius: 50%;
}
.cast-card {
  width: 18%;
  border: none;
  margin: 5px 9px;
  border-radius: 0;
  padding: 10px 10px;
  margin-bottom: 4px;
  box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
  background-color: #fff;
  text-align: center;
}
.card-content {
  text-align: left;
  padding: 20px 0;
}
.profile-menu-wrapper {
  padding: 10px 0;
}
.cast-heading {
  text-transform: capitalize;
}

.activedash {
  background-color: #41b4fc;
  border: 1px solid black;
  border-radius: 3px;
  color: white;
}
